import React, { Component } from 'react';
import Route from './Route'
import './English.css'

export default class English extends Component {
  constructor() {
    super();

    this.state = {
      windowHeight: window.innerHeight,
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.onResize.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize.bind(this));
  }

  onResize() {
    this.setState({windowHeight: window.innerHeight});
  }

  render() {
    const {windowHeight } = this.state;

    return (
      <div className="English" style={{minHeight: windowHeight}}>
        <div className="container">
          <div className="top-title">
            The Story of a Christmas Present’s Journey
          </div>

          <div className="description">
            <p>
              Christmas is one of the most anticipated holidays but it is unfortunately also a major contributor of mass consumption culture and the creation of excessive waste within society.
            </p>
            <p>
              Recently these issues have become more prevalent even in non-religious nations such as Japan. Family members who gift children with special, personal Christmas presents can teach children to treasure their gifts instead of eventually just throwing them away.
            </p>
            <p>
              With this in mind, we have created a picture book to be included along with gift giving called "The Story of a Christmas Present’s Journey".
              This illustrated storybook shows how much effort Santa and the reindeer put into delivering gifts to children all over Japan.
            </p>
          </div>

          <div className="youtube">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/khwmPygivVY" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </div>
        </div>

        <div className="HowItWorks">
          <div className="container">
            <div className="title">
              How it works
            </div>

            <div className="description">
              <p>
                We defined the coordinates of elementary schools all over Japan as "the city where children live", 
                and calculated the most efficient route for Santa using a mathematical formula from "traveling salesman problem" 
                and the book describes Santa's gift delivery process 
                which circles 1.5 rounds distance of the earth at 6.5 times the speed of sound.
              </p>
            </div>
          </div>
          <Route></Route>
        </div>

        <div className="container">
          <div className="shoei-logo">
            <img src="images/shoei-logo.svg" alt=""/>
          </div>
        </div>
      </div>
    )
  }
}
