import { useEffect, useState, useRef } from "react";
import * as d3 from "d3";
import {event as d3_event} from 'd3-selection';

import japanGeoJSON from './json/japan.geojson'


function createMap(japan, svg) {

  var width = 600,
  height = 600;
  var scale = 1600;

  console.log('hello japan')


  var aProjection = d3.geoMercator()
      .center([ 136.0, 35.6 ])
      .translate([width/2, height/2])
      .scale(scale);
  var geoPath = d3.geoPath().projection(aProjection);
  svg = d3.select(svg).attr("width",width).attr("height",height);

  //マップ描画
  var map = svg.selectAll("path").data(japan.features)
    .enter()
    .append("path")
      .attr("d", geoPath)
      .style("stroke", "#ffffff")
      .style("stroke-width", 0.1)
      .style("fill", "#5EAFC6");

  // //ズームイベント設定    
  // var zoom = d3.zoom().on('zoom', function(e){
  //     aProjection.scale(scale * e.transform.k);
  //     map.attr('d', geoPath);
  // });
  // svg.call(zoom);
  
  // // ドラッグイベント設定
  // var drag = d3.drag().on('drag', function(e){
  //     var tl = aProjection.translate();
  //     aProjection.translate([tl[0] + e.dx, tl[1] + e.dy]);
  //     map.attr('d', geoPath);
  // });
  // map.call(drag);
}

export default function Route() {

  const [map, setMap] = useState(null);
  const svgRef = useRef(null);

  useEffect(() => {
    console.log(svgRef.current)

    d3.json(japanGeoJSON).then(japan =>  {
        createMap(japan, svgRef.current);
      }
    )


    // })

    return () => {

    }
  }, []);


  return (
  <div className="Route">
    <svg ref={svgRef}></svg>
  </div>
  )
}
