import React, {Component} from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import English from "./English";

import './App.css';

function BookSample(props) {
  const {src, text} = props;

  return (
    <div className="book-sample" style={{
      marginBottom: 20,
    }}>
      <img src={src} alt="" style={{
        width: "100%",
      }}/>
      <p>{text}</p>
    </div>
  );
}

function AmazonLink(props) {

  return (
    <div className="AmazonLink clearfix" style={{
      fontSize: 20,
      fontWeight: 'bold',
      border: "1px solid black",
      borderRadius: 12,
      margin: "0 16px 12px",
      padding: "8px",
    }}>
      <a href="https://www.amazon.co.jp/" className="clearfix" style={{
        display: "block",
        verticalAlign: 'middle',
        height: 40,
      }}
        >
        <img src="/images/gift.png" alt="" style={{
          height: 40,
          marginRight: 8,
          verticalAlign: 'middle',
        }} />
        <span style={{
          height: 40,
          verticalAlign: 'middle',
          color: 'black',
        }}>
          Amazonで購入
        </span>
      </a>
    </div>
    )
}

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/store">
          <Store />
        </Route>
        <Route path="/en">
          <English />
        </Route>
        <Route path="/">
          <Main />
        </Route>
      </Switch>
    </Router>
  );
}

function Store() {
  console.log("store")
  window.location = "https://xmaskiseki.stores.jp/items/5fc458addf515936aad20094"
  return (
    <div className="Store">
    </div>
  )
}



function YouTube() {
  // const size = useWindowSize();

  return (
    <div className="youtube"
      style={{
        position: 'relative',
        width: '100%',
        paddingTop: `${9/16 * 100}%`,
        marginBottom: 32,
      }}
    >
      <iframe 
        width="560" 
        height="315" 
        src="https://www.youtube.com/embed/oNcU56Su4XI" 
        title="YouTube video player" 
        frameBorder="0" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
        allowFullScreen
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
          width: '100%',
          height: '100%',
        }}
        ></iframe>
    </div>

  );
}


class Main extends Component {
  render() {
    // const toShow = window.location.search.match("password=kiseki")
    // if (!toShow) {
    //   return (<div></div>)
    // }

    return (
      <div className="App" style={{
        textAlign: 'center',
        color: '#1C327D',
        }}>
        {/* <div className="background" style={{
          position: 'fixed',
          opacity: 0.5,
          width: "100%", height: "100%",
          zIndex: 0,
          backgroundImage: "url('/images/skytree_min.jpg')",
          backgorundSize: "cover",
        }}> </div> */}

        <div className="container" style={{ 
          paddingBottom: 40,
          position: "relative", 
          zIndex: 1, 
          width: "100%",
          maxWidth: 400,
          }}>

          <div className="title" style={{ 
              padding: "100px 0 60px",
            }}>
            <img 
              src="/images/title.svg" alt="クリスマスのキセキ"
              style={{
                width: 240,
              }}
              />
          </div>

          <YouTube />

          <div className="main-visual">
            <div>
              <img 
                src="/images/books/kv_1.jpg" alt="クリスマスのキセキ"
                style={{
                  width: "100%",
                  verticalAlign: "bottom",
                }}
                />
            </div>
            <div>
              <img 
                src="/images/books/kv_4.jpg" alt="クリスマスのキセキ"
                style={{
                  width: "100%",
                  verticalAlign: "bottom",
                }}
                />
              </div>
          </div>
          
          <div className="description" style={{ 
            paddingTop: 60,
            paddingBottom: 40,
            }}>
            <div>
              <img 
                src="/images/catch-copy.svg" alt="このクリスマス、プレゼントと一緒に贈りたい贈りたい絵本。"
                style={{
                  width: 200,
                  marginBottom: 32,
                }}
                />
            </div>

            <div style={{
                fontSize: 12,
                fontWeight: "bold",
                textAlign: "left",
                width: 210,
                margin: "0 auto",
                marginBottom: 32,
              }}>
              <p>世界初！</p>
              <p>最新テクノロジーの</p>
              <p>シミュレーションによって、</p>
              <p>サンタさんの最も効率的な</p>
              <p>配送ルートが明らかに！</p>
            </div>

            <div style={{
                fontSize: 12,
                fontWeight: "bold",
                textAlign: "left",
                width: 210,
                margin: "0 auto",
              }}>
              <p>一晩でプレゼントを届けるために、</p>
              <p>超音速で日本全国を駆け回る</p>
              <p>サンタさんとトナカイくんの</p>
              <p>｢軌跡｣の物語を絵本化。</p>
            </div>
          </div>

          {/* ストアリンク */}
          <div className="store-link" style={{
            marginBottom: 40,
          }}>
            <a href="https://xmaskiseki.stores.jp/items/5fc458addf515936aad20094">
              <img src="/images/store-button.svg" alt=""
                style={{width: 240}}
              />
            </a>
          </div>

          <div className="map-video">
            <video 
              src="/videos/route_video.mp4"
              style={{
                width: "100%",
              }}
              controls={false}
              autoPlay={true}
              loop={true}
              muted={true}
              playsInline={true}
              onClick="this.play();"
            ></video>
          </div>

          <div className="text">
            <div style={{
              padding: "60px 16px",
            }}>
              <div className="title" style={{
                fontWeight: "bold", 
                fontSize: 16,
                marginBottom: 32,
                }}>
                <p> サンタはどうやって</p>
                <p>一晩でプレゼントを配っている？！ </p>
                <p> 最適化計算により明らかになった </p>
                <p> サンタクロースの最高効率配送ルート </p>
              </div>

              <p>
                「クリスマスのキセキ」では、約2万の全国の小学校の座標を｢子どもの住んでいる町｣と定義し、そのすべてを最も効率良く回るルートを、所定の複数箇所を1回ずつ巡回する場合の最短経路を求める数学の難問「巡回セールスマン問題」の解法を用いて計算、日本地図上にビジュアライズしました。緻密に計算されたルートは、見ごたえのある内容となっています。自分の町を何時にサンタたちが通るのか、子どもと一緒に探してみましょう。
              </p>
            </div>
          </div>

          <img 
            src="/images/books/kv_2.jpg" alt=""
            style={{
              width: "100%",
            }} />

          <div className="text">
            <div style={{
              padding: "60px 16px",
            }}>
              <div className="title" style={{
                fontWeight: "bold", 
                fontSize: 16,
                marginBottom: 32,
                }}>
                <p> 新型コロナの影響で </p>
                <p> 旅行に行けない子どもたちへ。 </p>
                <p> 最新の3D技術で </p>
                <p> 日本各地のクリスマスの夜を再現！ </p>
              </div>

              <p>
                この絵本では、Google Earth Studioが提供する実際の町並みの3Dデータと、配送ルートを重ねることで、実際のサンタクロースとトナカイの見え方をシミュレーションしました。各都市の上空を飛び回るサンタクロースの様子を、最新3D技術で現実世界に忠実に表現し、より想像力をかきたてる内容となっています。 このクリスマスの夜、絵本でサンタのプレゼント配りについて行ってみませんか？
              </p>
            </div>
          </div>

          <img 
            src="/images/books/kv_3.jpg" alt=""
            style={{
              width: "100%",
            }} />

          <div className="text">
            <div style={{
              padding: "60px 16px",
            }}>
              <div className="title" style={{
                fontWeight: "bold", 
                fontSize: 16,
                marginBottom: 32,
                }}>
                <p>｢サンタ、苦労す、、、｣</p>
                <p>サンタとトナカイの、</p>
                <p>過酷だけど</p>
                <p>ちょっと楽しげなストーリー</p>
              </div>
              <p>
                ちょっと寄り道グセのあるサンタさん。地元の名産品に気をとられたり、富士山に寄り道したり…奈良ではトナカイくんに思いがけない出会いが。過酷な旅路でも、楽しげにこなしていく二人のストーリーをイラストで表現しました。
              </p>
            </div>
          </div>

          <img 
            src="/images/books/kv_5.jpg" alt=""
            style={{
              width: "100%",
            }} />


          <div className="row" style={{
            paddingTop: 60,
            marginBottom: 10,
          }}>
            {/* トナカイプロフィール */}
            <div className="col s6">
              <img src="/images/tonakai.svg" alt="" style={{
                width: 140,
                marginBottom: 16,
              }} />

              <img src="/images/tonakai-profile.svg" alt="" style={{
                // width: "100%",
                width: 140,
              }} />

              <div className="profile" style={{
                position: 'relative',
                fontWeight: 'bold',
                fontSize: 10,
                transform: "scale(0.75)",
                width: 200,
              }}>
              </div>
            </div>

            {/* サンタプロフィール */}
            <div className="col s6">
              <img src="/images/santa.svg" alt="" style={{
                width: 140,
                marginBottom: 16,
              }} />

              <img src="/images/santa-profile.svg" alt="" style={{
                width: 140,
              }} />

            </div>
          </div>

          {/* <AmazonLink></AmazonLink>

          <div className="instagram-link" style={{
            marginTop: 32,
            marginBottom: 32,
          }}>
            <a href="https://www.instagram.com/" style={{
              color: 'black',
              fontWeight: 'bold',
              fontSize: 20,
            }}>
              <img src="/images/instagram.png" alt="" style={{
                height: 40,
                verticalAlign: 'middle',
                marginRight: 8,
                }}/>
              Instagram
            </a>
          </div> */}

          <div className="text">
            <div style={{
              padding: "60px 16px 32px",
            }}>
              <div className="title" style={{
                fontWeight: "bold", 
                fontSize: 16,
                marginBottom: 32,
                }}>
                <p>｢クリスマスのキセキ｣</p>
                <p>オンラインストアおよび一部書店にて</p>
                <p>発売開始！</p>
              </div>

              <p style={{marginBottom: 32}}>
                クリスマスプレゼントの裏に隠された旅路を描く絵本、｢クリスマスのキセキ｣は2020年12月3日に発売開始です。クリスマスのプレゼントに添えて、ぜひお買い求め下さい。
              </p>

              <div className="spec" style={{
                fontWeight: "bold",
              }}>
                <p>対象年齢 4才〜おとなまで</p>
                <p>B4版 56ページ</p>
              </div>
            </div>
          </div>

          {/* ストア情報 */}
          <div className="store-link" style={{
            marginBottom: 0,
          }}>
            <a href="https://xmaskiseki.stores.jp/items/5fc458addf515936aad20094">
              <img src="/images/store-button.svg" alt=""
                style={{width: 240}}
              />
            </a>
          </div>

          <div className="text">
            <div style={{
              padding: "32px 16px 24px",
            }}>
              <div className="title" style={{
                fontWeight: "bold", 
                fontSize: 16,
                marginBottom: 8,
                }}>
                <p>販売店舗</p>
              </div>
              <p>
                代官山 蔦屋書店
              </p>
              <div
                style={{padding: "0 32px", overflowWrap: "break-word"}}
              >
                <a 
                  href="https://goo.gl/maps/ukRMQ7Qf4Q3iz9ns9"
                  style={{
                    color: "#1C327D",
                    textDecoration: "underline",
                  }}
                  >
                  東京都渋谷区猿楽町17-5
                </a>
              </div>
            </div>
          </div>

          <div className="insta-link" style={{
            marginBottom: 24,
          }}>
            <a href="https://www.instagram.com/xmas_kiseki">
              <img src="/images/instagram.svg" alt=""
                style={{width: 160}}
              />
            </a>
          </div>


          <div className="footer" style={{
            fontWeight: "bold",
          }}>
            クリスマスのキセキ　製作委員会
          </div>
        </div>
      </div>
    );
  }
}

export default App;
